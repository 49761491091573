
$background-color: rgb(250, 250, 250);
$main-color: #550065;
$secondary_color: gold;
$secondary_color_shade: gold;


@mixin tablet {
    @media screen and (max-width: 1024px) {
        @content;
    }
}
@mixin mobile {
    @media screen and (max-width: 500px) {
        @content;
    }
}

@keyframes bg-animation {
    0% {
        background-position: center left;
    }

    100% {
        background-position: center center;
    }
}
// @keyframes bg-animation {
//     0% {
//         background-position: center left;
//     }

//     100% {
//         background-position: center center;
//     }
// }


@keyframes live-animation {
    0% {
        background-position: 0% 100%;
    }

    // 50% {
    //     background-position: 100% 50%;
    // }

    100% {
        background-position: 200% 0%;
    }
}



@keyframes wobble {
    16.65% {
        transform: translateX(8px);
    }
    33.3% {
        transform: translateX(-6px);
    }
    49.95% {
        transform: translateX(4px);
    }
    66.6% {
        transform: translateX(-2px);
    }
    66.6% {
        transform: translateX(1px);
    }
    100% {
        transform: translateX(0);
    }
}


@keyframes pulse {
    0% {
        transform: scale(1);
    }
    70% {
        transform: scale(.8);
    }
        100% {
        transform: scale(1);
    }
}

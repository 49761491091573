@import url(../../styles/_global-variables.scss);

.reusableCard {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 8px 13px rgba(0,0,0,.1);


    .reusableCardTitle {
        display: flex;
        // border-bottom: 1px solid #f1f1f1;
        padding: 10px 5px;
        justify-content: space-between;
        align-items: center;        
        font-weight: 600;
    }
    
    .reusableCardBody {
        padding: 10px 5px;
        position: relative;
    }
}
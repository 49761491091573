@import '../../styles/_global-variables.scss';

.toggleQueries {
    transition: 0.3s;

    @include tablet {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba($color: #000000, $alpha: 0.5);
        // display: grid;
        z-index: 999;
        place-items: center;

        &.open {
            display: grid;
        }
    }

    .toggleCard{
        width: 95%;
    }


    .bettingSlip {

        .goDown {
            display: none;

            @include tablet {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                cursor: pointer;

            }

            .icon {
                font-size: 20px;
            }
        }
    
    
        .css-heg063-MuiTabs-flexContainer,
        .MuiTabs-flexContainer {
            justify-content: space-around;
    
            // .css-1q2h7u5.Mui-selected,
            // .Mui-selected,
            // .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
            .MuiTab-textColorPrimary.Mui-selected {
                color: $main-color !important;
            }
    
            // button {
            //     text-transform: capitalize;
            // }
        }
        .css-13xfq8m-MuiTabPanel-root,
        .css-19kzrtu {
            padding: 24px 1px !important;
        }
    
        .bets {
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;
    
    
            .bettingList {
                background-color: $main-color;
                color: white;
                display: grid;
                grid-template-columns: 15% auto 15%;
                font-size: 14px;
                border-radius: 10px;
                transition: 0.5s;
    
                .removeButton {
                    display: grid;
                    place-items: center;
                    cursor: pointer;
    
                    .icon {
                        color: #f1f1f1;
    
                    }
                }
                
                .game {
                    padding: 5px;
                    display: grid;
                    
                    .type {
                        color: #eee;
                        padding: 2px;
                        display: flex;
                        gap: 4px;
                        align-items: center;
                        font-size: 13px;
                        font-weight: 500;
                        
    
                        .icon {
                            // color: #f1f1f1;
                            font-size: 16px;
                            font-size: bold;
                        }
                    }
                    
                    .competition {
                        font-size: 13px;
                        padding: 2px;
                        font-weight: 500;
                    }
    
                    .market {
                        font-size: 13px;
                        padding: 2px;
                        font-weight: 500;
                        color: #eee;
                    }
                }
    
                .odd {
                    display: grid;
                    padding-top: 5px;
                    font-weight: 600;
                    color: $secondary-color-shade;
                }
            }
        }
    
    
        .totalling {
            display: grid;
            box-shadow: 0 -5px 5px rgba($color: #000000, $alpha: 0.1);
            padding: 5px;
    
            .tto {
                display: flex;
                padding: 10px;
                font-size: 14px;
                font-weight: 600;
                justify-content: space-between;
                align-items: center;
    
                input {
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    padding: 5px;
                    font-size: 14px;
                    outline: none;
                }
    
                &.win {
                    background-color: rgba($color: $main-color, $alpha: 0.1);
                    border-radius: 5px;
                }
            }
    
            .placeBet {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 30px;
    
                button {
                    outline: none;
                    border: none;
                    background-color: $main-color;
                    color: white;
                    font-size: 14px;
                    font-weight: 600;
                    transition: .3s;
                    border-radius: 5px;
                    cursor: pointer;
                    padding: 8px 41px;
                    box-shadow: 0 7px 10px rgba(0,0,0,.1);
        
                    &:hover {
                        background-color: $secondary_color_shade;
                        animation-name: wobble;
                        animation-duration: 1s;
                        animation-timing-function: ease-in-out;
                        animation-iteration-count: 1;
                    }
                }
            }
        }
    
        .lac {
    
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 10px;
    
    
            input {
                border-radius: 5px;
                border: 1px solid #ccc;
                padding: 10px;
                outline: none;
                width: 100%;
                height: 50px;
                font-size: 14px;
            }
    
            
            button {
                margin-top: 30px;
                outline: none;
                border: none;
                background-color: $main-color;
                color: white;
                font-size: 14px;
                font-weight: 600;
                transition: .3s;
                border-radius: 5px;
                cursor: pointer;
                padding: 10px 41px;
                box-shadow: 0 7px 10px rgba(0,0,0,.1);
    
                &:hover {
                    background-color: $secondary_color_shade;
                    animation-name: wobble;
                    animation-duration: 1s;
                    animation-timing-function: ease-in-out;
                    animation-iteration-count: 1;
                }
            }
    
        }
    }

}

.floatingButton {
    display: none;
    cursor: pointer;
    box-shadow: 0 8px 13px rgba(0,0,0,.1);
    position: fixed;
    bottom: 5%;
    right: 5%;
    background-color: rgba($color: #ffffff, $alpha: 0.8);
    display: grid;
    place-items: center;
    padding: 5px;
    font-size: small;
    z-index: 999;
    font-weight: 500;

        
    .betCount {
        background-color: $main-color;
        color: $secondary_color;
        display: grid;
        font-size: medium;
        place-items: center;
        border-radius: 50%;
        font-weight: 600;
        width: 25px;
        height: 25px;
        // padding: 5px;
    }


    
    @include tablet {
        right: 0;
        bottom: 15%;
    }
}

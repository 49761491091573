@import "../../styles/global-variables";

.sidebar {
    flex: 1;
    // border-right: 0.5px solid #f3f3f3;
    background: white;
    min-height: 100vh;
    border-end-end-radius: 20px;
    box-shadow: 0 0 5px #eee;
    
    @include tablet {
        display: none;
        flex: none;
        position: absolute;
        transition: right 0.5s ease-in-out;
        
        &.open {
            display: block;
            z-index: 2;
            top: 0;
            bottom: 0;
            right: 65%;
            left: 0;
            
            @include mobile {
                right: 15%;
            }
            
            .top {
                .close {
                    display: block;
                    box-shadow: 3px 2px 7px 2px #eee;
                    border-radius: 5px;
                    position: absolute;
                    right: -6%;
                    z-index: 2;
                    background: white;
                    color: #000;
                }

            }
        }
    }
    @include mobile {
        display: none;
        flex: none;
    }
    
    .top {
        height: 60px;
        display: flex;
        // justify-content: center;
        align-items: center;
        // border-bottom: 1px solid #f1f1f1;
        position: relative;
        
        .close{
            display: none;
            
        }
        
        
        .logo {
            height: 100%;
            padding: 40px;
            font-weight: bold;
            font-size: 30px;
            display: flex;
            align-items: center;
            color: $main-color;
            cursor: pointer;
            letter-spacing: 2px;

            .logoBall {
                color: #bebcbc;
                font-size: 26px;
            }

            .logoSpan {
                font-weight: lighter;
                color: $secondary_color;
            }
        }
    }

    .hr {
        display: grid;
        place-items: center;


        hr {
            background-image: linear-gradient(to right, $main-color 0%, $secondary_color 51%, $main-color 100%);
            // background-color:  rgba($color: #550065, $alpha: 0.7);
            border: $main-color;
            height: 4px;
            border-radius: 6px;
            width: 70%;
        }
    }

    .center {
        padding: 15px 0;

        .title {
            margin-left: 20px;
            font-weight: 600;
            color: gray;
            margin-top: 15px;
        }
        
        ul {
            list-style: none;
            display: grid;
            grid-gap: 0.5rem;
            margin: 0;
            padding: 0;

            .title {
                font-size: 14px;
            }

            .listItems {
                display: grid;
                place-items: center;
                grid-gap: 1rem;

                .icon {
                    font-size: 18px;
                }

                a {
                    display: grid;
                    place-items: center;
                    color: black;
                    width: 85%;
                    
                    
                    li {
                        width: 100%;
                        // border: 1px solid #000;
                        padding: 12px;
                        gap: 10px;
                        font-size: 14px;
                        border-radius: 10px;
                        background: white;
                        transition: 0.2s;
                        // box-shadow: 0 0 8px #eee;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        
                        // -webkit-transition: background 300ms ease-in-out;
                        // transition: background 300ms ease-in-out;
    
                        &:hover {
                            background: rgba($color: $main-color, $alpha: 0.2) ;
                            transform: scale(0.97);
                            // background-position: right left;
                            // color: white;
                        }

                        
                    }

                    &.active {
                        li {
                            background-color: $main-color;
                            color: white;
                            backdrop-filter: blur(30px);
                            
                        }
                    }

                }
    
            }

        }
    }
    
    
    .box {
        width: 200px;
        height: 200px;
        display: grid;
        place-items: center;
        background-color: #f1f1f1;
        color: #f8f8f8;
        margin: 10px;
        
    }
    


}
@import '../../styles/global-variables';

// $rccs-default-background: linear-gradient(45deg, $main-color, $secondary_color);
$rccs-unknown-background: linear-gradient(45deg, $main-color, $secondary_color);
// $rccs-unknown-background: url('../../bgc.jpg');

@import 'react-credit-cards-2/lib/styles.scss';


.creditcard {
    // border: 1px solid #000;

    .formBox {
        // border: 1px solid red;
        position: relative;
        margin-top: 110px;
        // background: url('../../bgc.jpg');
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 8px 13px rgba(0,0,0,.1);
    
        .credit {
            position: absolute;
            top: -110px;
            border-radius: 15px;
            box-shadow: 0 10px 15px rgba(0,0,0,.1);
            // display: flex;
            // justify-content: center;
            // align-items: center;

        }


        form {
            // border: 1px solid gold;
            padding-top: 100px;
            width: 100%;
            display: grid;
            place-items: center;
            padding-bottom: 20px;
            gap: 15px;
            

            .formInput {
                width: 80%;
                display: grid;
                font-size: 14px;
                gap: 5px;
                
                label {
                    font-weight: 500;
                }
                
                input {
                    font-size: 14px;
                    width: 100%;
                    padding: 10px;
                    border-radius: 10px;
                    outline: none;
                    border: 1px solid #eee;
                }
            }

            button {
                outline: none;
                border: none;
                background-color: $main-color;
                color: white;
                font-size: 14px;
                font-weight: 600;
                transition: .3s;
                border-radius: 5px;
                cursor: pointer;
                padding: 8px 41px;
                box-shadow: 0 7px 10px rgba(0,0,0,.1);
    
                &:hover {
                    background-color: $secondary_color_shade;
                    animation-name: wobble;
                    animation-duration: 1s;
                    animation-timing-function: ease-in-out;
                    animation-iteration-count: 1;
                }
            }
        }
    }
}
@import "../../styles/_global-variables.scss";

.adcard {
    display: flex;
    background-image: linear-gradient(to right, $main-color 0%, $secondary_color 51%, $main-color 100%);
    background-size: 120% auto;
    animation: bg-animation 2s infinite alternate;
    color: white;
    height: 130px;
    padding: 0 10px;
    border-radius: 10px;
    transition: 0.5s;
    justify-content: space-between;
    box-shadow: 0 8px 13px rgba(0,0,0,.1);
    
    @include tablet {

        height: 100px;
        font-size: 15px;
    }

    .adText {
        // border: 1px solid #000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px;

        // @include tablet {

        //     font-size: 15px;
        // }
    }

    .adImage {
        // border: 1px solid #000;
        display: flex;
        justify-content: center;
        align-items: center;

        .image {
            width: 150px;
            height: 130px;
            object-fit: cover;
            
            // border: 1px solid #000;
        }
        @include tablet {
            display: none;
            
        }
    }

    // &:hover {
    //     background-position: center left;
    // }
}
// background-image: linear-gradient(to right, #550065 0%, #b534cf 51%, #550065 100%);

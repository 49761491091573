@import "../../styles/global-variables";

.oddstable {
    box-shadow: 0 10px 15px rgba(0,0,0,.1);
    width: 100%;
    height: 350px;
    overflow-y: auto;


    h4 {
        display: grid;
        padding: 10px;
        width: fit-content;
        place-items: center;
        background:  linear-gradient(45deg, $background-color,  white);
        // box-shadow: 3px 3px 3px #dfdfdf;
        border-radius: 5px;
        margin-bottom: 4px;
        font-weight: bold;
        color: rgb(22, 22, 22);
    }

    .switch-field {
        font-family: "Lucida Grande", Tahoma, Verdana, sans-serif;
        padding: 10px;
        overflow: hidden;
        border-top: 1px solid #ccc;

        .switch-title {
            font-weight: 500;
            margin-bottom: 6px;
            color: #000;
            text-transform: capitalize;
        }

      .switchers {
        display: grid;
        // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        margin-bottom: 15px;

        // @include tablet {
        //     grid-template-columns: 1fr 1fr 1fr;
        // }
      
        
        .switch{
            display: grid;
            position: relative;
            grid-template-rows: repeat(2, 1fr);
            row-gap: 10px;

            .switch-header {
                display: grid;
                background-color: white;
                color: black;
                font-size: 14px;
                font-weight: 600;
                place-items: center;
                text-shadow: none;
                padding: 6px 14px;
                box-shadow: inset 0 1px 3px #e4e4e4;
                        
            }
            .switch-item {
                position: relative;
            }

            input {
                position: absolute !important;
                height: 100%;
                width: 100%;
                z-index: 2;
                opacity: 0;
                cursor: pointer;


                &:checked + label {
                    background:  linear-gradient(45deg, rgb(154, 0, 185, 1) 0%,  rgb(130, 4, 155, 1) 35%,   rgb(85, 0, 101, 1) 100%,);
                    color: gold;
                    box-shadow: none;
                }
            }
            
            label {
                transition: all 0.1s ease-in-out;
                cursor: pointer;
                display: grid;
                place-items: center;
                background: linear-gradient(45deg, rgba(#e4e4e4, 1),  $background-color);
                box-shadow: 3px 3px 3px #d4d4d4;
                border-radius: 5px;
                font-size: 16px;
                // backdrop-filter: blur(5px);
                font-weight: 600;
                padding: 6px 14px;

            }
        }
      }

    }


}
@import url(../../styles/_global-variables.scss);

.matchesCard {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 8px 13px rgba(0,0,0,.1);
    height: 275px;
    
    
    
    .matchesCardTitle {
        display: flex;
        // border-bottom: 1px solid #f1f1f1;
        padding: 10px 5px;
        justify-content: space-between;
        align-items: center;        
        font-weight: 600;
        
        .icons {
            display: flex;
            gap: 3px;

            .icon {
                background: #f1f1f1;
                border-radius: 4px;
                color: #8f8f8f;
                box-shadow: 1px 3px 3px #d4d4d4;

                &:hover {
                    box-shadow: none;
                }
            }
        }
    }
    
    .matchesCardBody {
        padding: 10px 5px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            background-color: transparent;
            width: 7px;
            height: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(100, 100, 100, 0.7);
            border-radius: 5px;
        }

        
        .matchesCardItem {
            display: grid;
            border: 1px solid #f1f1f1;
            padding: 10px 5px;
            border-radius: 5px;
            grid-template-columns: 15% auto 15%;
            cursor: pointer;
            // background-color: #550065;

            &.active {
                background: #f1f1f1;
            }
            
            .teamCard {
                // border: 1px solid #f1f1f1;
                display: grid;
                row-gap: 5px;
                
                
                .team {
                    // color: gold;
                    // color: #550065;
                    font-weight: 600;
                    
                    display: grid;
                    grid-template-columns: 20% auto 10%;
                    font-size: 14px;
                    
                    .teamLogo {
                        display: grid;

                        .logo {
                            width: 20px;
                            height: 20px;
                            object-fit: cover;
                        }
                    }
                    
                    .teamName {
                        display: flex;
                        align-items: center;
                    }
                }
            }

            .status {
                font-size: small;
                // color: #f9f9f9;

            }

            .chart {
                display: flex;
                font-size: 12px;
                gap: 3px;
                font-weight: 500;

                .nm {
                    display: grid;
                    place-items: center;
                }
                
                .aggregate {
                    display: grid;
                }
            }


            
        }
    }

}
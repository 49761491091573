@import "../../styles/_global-variables.scss";

.card {
    background: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 8px 13px rgba(0,0,0,.1);
    
    @include tablet {
        padding: 5px;
    }


    .cardTitle {
        display: flex;
        border-bottom: 1px solid #f1f1f1;
        padding: 10px 5px;
        justify-content: space-between;
        align-items: center;

        @include tablet {
            
        }
        
        .cardTitleLive {
            font-size: 13px;
            display: flex;
            padding: 5px 10px;
            border-radius: 5px;
            font-weight: 600;
            align-items: center;
            cursor: pointer;
            
            &.off {
                color: gray;
                background-color: #eee;
                
                .icon {
                    color: gray;
                }
            }
            &.on {
                color: red;
                background-color: rgba($color: red, $alpha: 0.2);

                .icon {
                    color: red;
                    animation: pulse 1s infinite;
                }
            }

            @include tablet {
                font-size: 11px;
            }
            
            .icon {
                font-size: 13px;
                transition: .3s;
            }
        }
        
        .cardTitleText {
            font-weight: 600;

            @include tablet {
                font-size: 14px;
            }
        }
        
        .cardTitleIcons {
            display: flex;
            gap: 10px;
            
            .icon {
                font-size: 22px;
                color: rgba($color: gray, $alpha: 0.5);
                transition: 0.5s;
                cursor: pointer;
                
                &:hover {
                    animation-name: pulse;
                    animation-duration: 1s;
                    animation-timing-function: ease-in-out;
                    animation-iteration-count: 1;
                    
                }
                
                // &:nth-child(3) {
                //     background-color: rgba($color: gray, $alpha: 0.5);
                //     color: white;
                //     animation-name: pulse;
                //     animation-duration: 1s;
                //     animation-timing-function: ease-in-out;
                //     animation-iteration-count: infinite;
                // }
                
                
                
                @include tablet {
                    font-size: 18px;
                }
            }

        }
    }

    .cardBody {
        display: grid;
        padding: 14px 10px;
        grid-template-columns: repeat(3, 1fr);
        place-items: center;
        padding: 0 3px;

        
        

        .team {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            font-size: 17px;
            font-weight: 600;

            @include tablet {
                font-size: 15px;
                padding: 2px;
            }

            .teamLogo {
                width: 100px;
                display: grid;
                place-items: center;
                height: 100px;
                border: 10px solid #f8f7f7;
                border-radius: 50%;
                box-shadow: 0 0 7px #eee;
                
                .logo {
                    width: 60%;
                    height: 60%;
                    object-fit: cover;
                    border: none;
                    outline: none;
                }

                @include tablet {
                    width: 70px;
                    height: 70px;
                }
            }
        }

        .matchStats {
            display: grid;
            place-items: center;
            padding: 10px;
            gap: 15px;


            .mainc {
                color: black;
            }
    
            .gold {
                color: $secondary_color;
            }
    
            .black {
                color: #000;
            }
            
            h1 {
                font-weight: 600;
                color: rgb(104, 104, 104);

                @include tablet {
                    font-size: 30px;
                }
            }

            h5 {
                color: $main-color;
                font-weight: 500;

                span {
                    font-weight: 600;
                }
            }

            .matchInfo {
                font-size: 12px;
                font-weight: 600;
                color: rgba($color: gray, $alpha: 0.5);

                span {
                    color: gray;
                    font-weight: bold;
                }

                &.timer {
                    color: gray;

                    // span {
                    //     color: red;
                    // }

                    &::after {
                        content: "";
                        display: block;
                        // border-bottom-width: 1px;
                        // border-bottom-color: #000;
                        // border-bottom-style: solid;
                        background-image: linear-gradient(to right, white 30%, $secondary_color_shade 100%);
                        background-size: 200% auto;
                        // border-bottom-right-radius: 70%;
                        // border-top-right-radius: 70%;
                        animation: live-animation 0.8s infinite alternate;
                        height: 2px;
                        margin-bottom: 1px;
                    }
                }

            }

            
            
            .oddsButtons {
                display: flex;
                justify-content: space-between;
                gap: 5px;

                .switchItem {
                    position: relative;
    

                    input {
                        position: absolute !important;
                        height: 100%;
                        width: 100%;
                        z-index: 2;
                        opacity: 0;
                        cursor: pointer;
        
        
                        &:checked + label {
                            background: $main-color;
                            color: $secondary_color_shade;
                            box-shadow: none;
                        }
                    }
                    
                    label {
                        transition: all 0.1s ease-in-out;
                        cursor: pointer;
                        display: grid;
                        border: 1px solid #f7f7f7;
                        place-items: center;
                        background: rgba(#e4e4e4, 0.5);
                        box-shadow: 1px 3px 3px #d4d4d4;
                        // color: gray;
                        // border-radius: 5px;
                        font-size: 12px;
                        // backdrop-filter: blur(5px);
                        font-weight: 600;
                        padding: 6px 14px;

                    }


                    &:nth-child(1) {

                        label {
                            border-start-start-radius: 40%;
                            border-end-start-radius: 40%;

                        }
                    }
                    
                    
                    &:nth-child(2) {

                        label {
                            border-radius: 5px;

                        }
                    }
                    
                    
                    &:nth-child(3) {

                        label {
                            border-end-end-radius: 40%;
                            border-start-end-radius: 40%;

                        }
                    }


                }

            }
        }
    }

    .cardActions {
        display: flex;
        justify-content: center;
        // align-items: center;
        // margin-top: 5px;
        position: relative;

        button {
            outline: none;
            border: none;
            position: absolute;
            bottom: -61px;
            font-size: 14px;
            font-weight: 600;
            background-color: $main-color;
            transition: .3s;
            color: white;
            border-radius: 5px;
            cursor: pointer;
            padding: 8px 41px;
            box-shadow: 0 10px 15px rgba(0,0,0,.1);

            &:hover {
                background-color: $secondary_color_shade;
                animation-name: wobble;
                animation-duration: 1s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: 1;
            }

            &:disabled {
                background-color: rgba($secondary_color_shade, 0.5);
            }

        }
        @include tablet {
            position: none;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 10px;

            button {
                position: relative;
                bottom: 0;

            }
            
        }

    }
}
